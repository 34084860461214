export enum Phase {
  Sunrise = 'Sunrise',
  Midday = 'Midday',
  Sunset = 'Sunset',
  Midnight = 'Midnight',
  LineaJourney = 'Linea Journey',
  ZkSyncJourney = 'zkSync Era Journey',
  MultiChainJourney = 'MultiChain Journey',
  BaseJourney = 'Base Journey',
  ScrollJourney = 'Scroll Journey',
  BlastAdventure = 'Blast Journey',
  TaikoJourney = 'Taiko Journey',
  TonJourney = 'TON Journey',
  EthereumJourney = 'Ethereum Journey',
  OptimismJourney = 'Optimism Journey',
  ArbitrumJourney = 'Arbitrum Journey',
  ZoraJourney = 'Zora Journey',
}

export interface Journey {
  id: number;
  phase: Phase;
  journey: string;
  isActive: boolean;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  reward: {
    id: number;
    name: string;
    description: string;
    discountPercentageBetweenEvm: number | null;
    discountPercentageBetweenMixed: number | null;
    discountPercentageBetweenNonEvm: number | null;
    metadata: {};
    tokenId: string;
    createdAt: string;
    updatedAt: string;
  };
  mintingNetworks: {
    name: string;
    chainId: number;
    contractAddress: string;
    explorerLink: string;
    imageUrl: string;
  }[];
  countOfNftHolders: number;
}

export interface PhaseTask {
  id: number;
  isCompleted: boolean;
  title: string;
  description?: string;
  actionName?: string;
  link?: string;
  images?: string[];
}

export interface MintNFTResponse {
  data: {
    account: string;
    eligible: boolean;
    signature: string;
    isClaimed: boolean;
    network: string;
  };
}

export enum Journeys {
  EarlySunrise = 'Early Sunrise',
  LateJourney = 'Sunrise Journey',
  Midday = 'Midday Journey',
  LineaJourney = 'Linea Adventure',
  ZkSyncJourney = 'zkSync Era Adventure',
  MultiChainJourney = 'MultiChain Adventure',
  BaseJourney = 'Base Adventure',
  ScrollJourney = 'Scroll Adventure',
  BlastJourney = 'Blast Adventure',
  TaikoJourney = 'Taiko Adventure',
  TonJourney = 'TON Adventure',
}
