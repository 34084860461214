import { QUESTS_PAGE_ADVENTURES, QUESTS_PAGE_PHASES } from './routes.constants';

interface Redirect {
  from: string;
  to: string;
}

export const redirects: Redirect[] = [
  {
    from: '/retro-adventures',
    to: QUESTS_PAGE_ADVENTURES,
  },
  {
    from: '/adventures',
    to: QUESTS_PAGE_ADVENTURES,
  },
  {
    from: '/phases/linea-adventure',
    to: QUESTS_PAGE_ADVENTURES,
  },
  {
    from: '/phases/zksync-adventure',
    to: QUESTS_PAGE_ADVENTURES,
  },
  {
    from: '/phases/multichain-adventure',
    to: QUESTS_PAGE_ADVENTURES,
  },
  {
    from: '/phases/base-adventure',
    to: QUESTS_PAGE_ADVENTURES,
  },
  {
    from: '/phases/sunrise-journey',
    to: QUESTS_PAGE_PHASES,
  },
  {
    from: '/phases/midday-journey',
    to: QUESTS_PAGE_PHASES,
  },
  {
    from: '/phases/journeys',
    to: QUESTS_PAGE_PHASES,
  },
  {
    from: '/phases',
    to: QUESTS_PAGE_PHASES,
  },
  {
    from: '/adventures',
    to: QUESTS_PAGE_ADVENTURES,
  },
  {
    from: '/adventures/linea',
    to: QUESTS_PAGE_ADVENTURES,
  },
  {
    from: '/adventures/zksync',
    to: QUESTS_PAGE_ADVENTURES,
  },
  {
    from: '/adventures/multichain',
    to: QUESTS_PAGE_ADVENTURES,
  },
  {
    from: '/adventures/base',
    to: QUESTS_PAGE_ADVENTURES,
  },
  {
    from: '/adventures/scroll',
    to: QUESTS_PAGE_ADVENTURES,
  },
  {
    from: '/adventures/blast',
    to: QUESTS_PAGE_ADVENTURES,
  },
  {
    from: '/adventures/taiko',
    to: QUESTS_PAGE_ADVENTURES,
  },
  {
    from: '/adventures/ton',
    to: QUESTS_PAGE_ADVENTURES,
  },
];
