import { useMemo } from 'react';
import { PhaseItem } from '../JourneyItem';
import { Other } from '../Other';
import { PhasesBlock } from '../PhasesBlock';
import { Adventure, adventures } from '@/Config/adventures';
import { ROUTES } from '@/constants/routes.constants';

export function ActivitiesTab() {
  const activeAdventures = useMemo(() => {
    return [] as Adventure[];
  }, []);

  return (
    <div className="notifications-menu--tabs-content">
      <PhasesBlock />
      {activeAdventures.length > 0 && (
        <div className="notifications-menu--group">
          <div className="notifications-menu--group-heading">Adventures</div>
          {activeAdventures.map(adventure => (
            <PhaseItem
              key={adventure.phase}
              href={ROUTES.QUESTS_PAGE_ADVENTURES}
              thumbnailSrc={adventure.nftImage}
              backgroundSrc={adventure.notificationBg}
            >
              {adventure.notificationText}
            </PhaseItem>
          ))}
        </div>
      )}
      <div className="notifications-menu--group">
        <div className="notifications-menu--group-heading">Other</div>
        <Other />
      </div>
    </div>
  );
}
