import { useMemo } from 'react';
import { PhaseItem } from '../JourneyItem';
import { NewDestinationItem } from '../NewDestinationItem';
import { Other } from '../Other';
import { PhasesBlock } from '../PhasesBlock';
import { adventures } from '@/Config/adventures';
import { ROUTES } from '@/constants/routes.constants';

export function LatestTab() {
  return (
    <div className="notifications-menu--tabs-content">
      <PhasesBlock showSunrise={false} />
      <div className="notifications-menu--group">
        <div className="notifications-menu--group-heading">
          New Destinations
        </div>
        {/* <NewDestinationItem chainId="0">
          Bridging to TON is now available on RetroBridge
        </NewDestinationItem>
        <NewDestinationItem chainId="900">
          Bridging to Solana is now available on RetroBridge
        </NewDestinationItem> */}
        <NewDestinationItem chainId="1000">
          Bridging to Tron is now available on RetroBridge
        </NewDestinationItem>
      </div>
      <div className="notifications-menu--group">
        <div className="notifications-menu--group-heading">Other</div>
        <Other />
      </div>
    </div>
  );
}
