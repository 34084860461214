import { InternalAxiosRequestConfig } from 'axios';
import { PHASES_API_URL } from '../Config/ApiConfig';
import { phaseBannerStore } from '../stores/phaseBanner.store';
import { WalletStore, walletStore } from '../stores/wallet.store';
import { INetwork } from '../types/apiTypes';
import { Journey, MintNFTResponse, Phase, PhaseTask } from '../types/phases';
import { logInGroup } from '../utils';
import { ApiService as Api } from './CoreApi';
import { retroPointsService } from './retroPoints';
import { questsPhasesStore } from '@/pages/quests/QuestsPhases/store';

const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  return config;
};
export class PhaseService {
  private _api: Api;
  constructor() {
    this._api = new Api(PHASES_API_URL, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    this._api.instance.defaults.headers.put['Content-Type'] =
      'application/json';
    this._api.instance.interceptors.request.use(config =>
      requestInterceptor(config)
    );
  }

  async getJourneys(
    phase: Phase,
    selectedJourneyIndex?: number,
    setStore = true
  ): Promise<Journey[] | null> {
    const url = '/api/journey/all';
    const { data } = await this._api.get<{ data: Journey[] }>(url, {
      params: {
        phase,
      },
    });

    return data;
  }

  async getPhaseTasks(
    journeyId: number,
    wallet?: string,
    setToStore = true
  ): Promise<PhaseTask[] | null> {
    try {
      const url = '/api/task/tasks';
      const { data } = await this._api.get<{ data: PhaseTask[] }>(url, {
        params: {
          journeyId,
          wallet: wallet?.length === 0 ? undefined : wallet,
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async getPhaseTaskStatus(
    taskId: number,
    wallet: string,
    journeyId?: Journey['id']
  ): Promise<boolean | null> {
    try {
      const url = '/api/task/refresh_result_task';

      if (!journeyId) return null;

      const journey = questsPhasesStore
        .getState()
        .journeys.find(j => j.id === journeyId);

      const { data } = await this._api.get<{ data: PhaseTask }>(url, {
        params: {
          journeyId: journeyId,
          taskId,
          wallet: wallet.length === 0 ? undefined : wallet,
        },
      });

      await phaseService.getClaimNFTStatus(
        wallet,
        undefined,
        journey?.phase,
        journey?.journey
      );

      await retroPointsService.userInfo();

      return data.isCompleted;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async getClaimNFTStatus(
    wallet: string,
    network?: string,
    phase?: Phase,
    journey?: string
  ): Promise<MintNFTResponse['data'] | null> {
    try {
      const url = '/api/task/can_user_claim_nft';

      const data = await this._api.get<MintNFTResponse>(url, {
        params: {
          phase: phase,
          wallet: wallet.length === 0 ? undefined : wallet,
          network: network ? network : undefined,
          journey: journey,
        },
      });

      return data.data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  // async updateSocialTask(taskId: number, wallet: string) {
  //   try {
  //     const url = '/api/task/social_network_complete';
  //     const {
  //       journeys,
  //       setTaskStatus,
  //       setIsNFTEligible,
  //       setIsNFTClaimed,
  //       setNFTClaimedNetwork,
  //     } = phaseStore.getState();
  //     const activeJourney = journeys.find(journey => journey.isActive);

  //     if (!activeJourney) return;

  //     const { data } = await this._api.put<{ data: PhaseTask }>(
  //       url,
  //       {},
  //       {
  //         params: {
  //           journeyId: activeJourney.id,
  //           taskId,
  //           wallet,
  //         },
  //       }
  //     );

  //     if (!data) throw new Error('No data');

  //     setTaskStatus(taskId, data.isCompleted);

  //     const res = await phaseService.getClaimNFTStatus(wallet);

  //     if (!res) return data;

  //     setIsNFTEligible(res.eligible);
  //     setIsNFTClaimed(res.isClaimed);
  //     setNFTClaimedNetwork(res.network);

  //     return data;
  //   } catch (error) {
  //     console.warn(error);
  //   }
  //   return undefined;
  // }

  // async getAmountOfParticipants() {
  //   try {
  //     const url = `/api/journey/amount_of_participants/${
  //       phaseStore.getState().phase
  //     }`;
  //     const { data } = await this._api.get<{ data: { amount: number } }>(url);

  //     phaseStore.setState({ uniqueParticipants: data.amount });

  //     return data.amount;
  //   } catch (error) {
  //     console.warn(error);
  //   }
  //   return 0;
  // }

  async getUserNFT(wallet: string, networkId: INetwork['id']) {
    try {
      const url = `/api/user_journey/user_nft/${wallet}/${networkId}`;
      const data = await this._api.get<WalletStore['NFTDiscount']>(url);

      walletStore.setState({ NFTDiscount: data });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async estimateAmountInPool() {
    try {
      const url = '/api/orders/estimate_amount_in_pool';
      const { data } = await this._api.get<{ data: { total_pool: number } }>(
        url
      );

      return data.total_pool;
    } catch (error) {
      console.warn(error);
    }
    return 0;
  }

  async getPhaseBannerContent(
    wallet: string,
    outSymbol: string,
    phase: Phase = Phase.Sunrise
  ) {
    const { setLoading, setContent } = phaseBannerStore.getState();
    setLoading(true);
    try {
      const url = `/api/user_journey/progress_tasks_by_journey/${wallet}/${phase}/${outSymbol}`;

      const { message, images } = await this._api.get<{
        message: string;
        images: string[];
      }>(url);

      setContent(message, images);

      return { message, images };
    } catch (error) {
      logInGroup('PhaseBanner', error);
    } finally {
      setLoading(false);
    }
    return undefined;
  }

  async mintTonNFT(wallet: string) {
    const url = `/api/user_journey/claim_ton_nft/${wallet}`;

    const data = await this._api.get<{
      wallet_address: string;
      is_claimed: true;
    }>(url);

    if (!data.is_claimed) {
      throw new Error('NFT not claimed');
    }

    return data;
  }
}

export const phaseService = new PhaseService();
