import { useTonAddress } from '@tonconnect/ui-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAccount as useEVMAccount } from 'wagmi';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { Globe } from '@/Assets/Icons/Globe';
import { DappsIcon } from '@/Assets/Icons/dapps';
import PlusIcon from '@/Assets/Icons/plus-icon.svg';
import retroIcon from '@/Assets/Icons/retroIcon.svg';
import WalletIcon from '@/Assets/Icons/wallet-icon.svg';
import { ROUTES } from '@/constants/routes.constants';
import '@/pages/SendPage/SendPage.css';
import { ConnectWalletModalWithDisabled } from '../ConnectWalletModalWithDisabled';
import MobileMenu from '../MobileMenu';

import { useStarknetAccount } from '@/starknet/hooks/account';
import { useWindowSize } from 'usehooks-ts';
import { NotificationsPopover } from '../NotificationsPopover';
import { WalletPopover } from './components/MobileWalletPopover';
import { MyHistoryPopover } from './components/MyHistoryPopover';
import { NetworkSwitch } from './components/NetworkSwitchPopover';
import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import './styles.css';
import { RetroBridgerPointsIcon } from '@/Assets/Icons/RetroBrdigePoints';
import historyIcon from '@/Assets/Icons/history.svg';

const Header = () => {
  const { width } = useWindowSize();
  const { address: account } = useEVMAccount();
  const { address: starknetAddress } = useStarknetAccount();
  const { publicKey: solanaAddress } = useSolanaWallet();
  const tonAddress = useTonAddress();
  const { address: tronAddress } = useTronWallet();

  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState(false);

  const isConnected =
    !!account ||
    !!starknetAddress ||
    !!tonAddress ||
    !!solanaAddress ||
    !!tronAddress;

  return (
    <header className="header app-header">
      <div className="d-lg-none d-flex" style={{ minWidth: '34px' }}>
        <MobileMenu isAppMenu />
      </div>
      <Link to={ROUTES.LANDING_PAGE} className="mx-auto mx-lg-0">
        <img
          src={retroIcon}
          alt="logo"
          style={{ width: '170px', height: '40px' }}
        />
      </Link>
      <nav className="header-nav ms-3">
        <Link to={ROUTES.QUESTS_PAGE} className="header-nav-item">
          <RetroBridgerPointsIcon width={18} height={13} />
          Retro Points
        </Link>
        <Link to={ROUTES.DAPP_EXPLORER_PAGE} className="header-nav-item">
          <DappsIcon />
          Dapps Explorer
        </Link>
      </nav>
      <div className="app-header-nav">
        <NetworkSwitch />
        <div className="header-nav-item--divider" />
        <NotificationsPopover />
        <div className="header-nav-item--divider" />
        <Link
          to={ROUTES.EXPLORER_PAGE}
          className="header-nav-item"
          aria-label="Explorer"
        >
          <img src={historyIcon} width={20} height={20} alt="" />
        </Link>
        {isConnected && <div className="header-nav-item--divider" />}
        {!isConnected ? (
          <button
            className="headerBtn header-nav-item fw-bold ms-0"
            onClick={() => setIsConnectDialogOpen(true)}
          >
            Connect wallet
          </button>
        ) : (
          <WalletPopover />
        )}
      </div>
      {width <= 993 && (
        <div className="me-3">
          <NotificationsPopover />
        </div>
      )}
      {!isConnected && (
        <div
          onClick={() => setIsConnectDialogOpen(true)}
          className="position-relative d-flex d-lg-none"
        >
          <button className="header-wallet-button header-mobile-button-background">
            <img
              className="header-mobile-button-img-1"
              src={WalletIcon}
              alt=""
            />
            <img className="header-mobile-button-img-2" src={PlusIcon} alt="" />
          </button>
        </div>
      )}
      {isConnected && (
        <div className="position-relative d-flex d-lg-none">
          <WalletPopover />
        </div>
      )}
      <div className="transaction-popover-mobile">
        <MyHistoryPopover />
      </div>
      <ConnectWalletModalWithDisabled
        openModal={isConnectDialogOpen}
        setOpenModal={setIsConnectDialogOpen}
      />
    </header>
  );
};

export default Header;
