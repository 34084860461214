import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@/Assets/fonts/satoshi/css/satoshi.css';
import '@/Assets/fonts/aeonikPro/aeonikPro.css';
import '@/Assets/fonts/joystix/joystix.css';
import './index.css';
import './var.css';
import { stage } from './Config/ApiConfig';
import registerServiceWorker from './serviceWorkers';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (stage === 'production') {
  require('./tag');
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

registerServiceWorker();
