import { ArrowDownIcon } from '@/Assets/Icons/arrow-down';
import { ArrowUpIcon } from '@/Assets/Icons/arrow-up';
import { PlusOutline } from '@/Assets/Icons/PlusOutline';
import { PowerIcon } from '@/Assets/Icons/Power';
import solanaIcon from '@/Assets/images/wallets/solana.png';
import starknetIcon from '@/Assets/images/wallets/starknet.png';
import tonIcon from '@/Assets/images/wallets/ton.png';
import { ConnectWalletModalWithDisabled } from '@/components/ConnectWalletModalWithDisabled';
import { useConnectedIcon } from '@/hooks/useConnectedIcon';
import { useSolanaConnectedIcon } from '@/hooks/useSolanaConnectedIcon';
import { useTonConnectedIcon } from '@/hooks/useTonConnectedIcon';
import {
  NetworkTypes,
  useWalletConnectInteractor,
} from '@/providers/web3Provider';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { useStarknetConnectedIcon } from '@/starknet/hooks/useConnectedIcon';
import { authStore } from '@/stores/auth.store';
import { useRetroPointsStore } from '@/stores/retroPoints.store';
import { useConnectedTronIcon } from '@/tron/hooks/useConnectedTronIcon';
import {
  PopoverClose,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
  Root,
} from '@radix-ui/react-popover';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { jsNumberForAddress } from 'react-jazzicon';
import Jazzicon from 'react-jazzicon/dist/Jazzicon';
import { useAccount, useDisconnect } from 'wagmi';
import { useShallow } from 'zustand/react/shallow';
import './styles.css';
import { formatAddress } from '@/utils';

export function WalletPopover() {
  const [isOpen, setIsOpen] = useState(false);
  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState(false);
  const [user] = useRetroPointsStore(useShallow(s => [s.user]));

  const { address: EVMAddress } = useAccount();
  const EVMWalletIcon = useConnectedIcon();
  const { disconnectAsync: disconnectEVM } = useDisconnect();

  const { address: starknetAddress } = useStarknetAccount();
  const starknetWalletIcon = useStarknetConnectedIcon();
  const { handleDeactivate } = useWalletConnectInteractor();

  const tonAddress = useTonAddress();
  const tonWalletIcon = useTonConnectedIcon();
  const [tonConnectUI] = useTonConnectUI();

  const { publicKey: solanaAddress, disconnect: disconnectSolana } =
    useSolanaWallet();
  const solanaWalletIcon = useSolanaConnectedIcon();

  const { address: tronAddress, disconnect: disconnectTron } = useTronWallet();
  const tronWalletIcon = useConnectedTronIcon();

  const disconnect = async () => {
    if (starknetAddress) {
      handleDeactivate(NetworkTypes.STARKNET);
    }
    if (tonAddress) {
      await tonConnectUI.disconnect();
    }
    if (solanaAddress) {
      await disconnectSolana();
    }
    if (tronAddress) {
      await disconnectTron();
    }
    await disconnectEVM();
    authStore.setState({ tokens: {} });
  };

  const isAllNonEVMConnected =
    user?.starknet_address &&
    user?.ton_address &&
    user?.solana_address &&
    user?.tron_address;

  if (!EVMAddress) return null;

  return (
    <div className="d-flex justify-content-center mt-3">
      <Root open={isOpen} onOpenChange={setIsOpen}>
        <div className="d-flex flex-column align-items-center">
          <PopoverTrigger className="quests-profile--wallet-trigger">
            <div className="d-flex align-items-center">
              <img
                src={EVMWalletIcon}
                className="quests-profile--wallet-trigger-icon"
                style={{
                  zIndex: 4,
                }}
                alt=""
              />
              {user?.starknet_address && (
                <div
                  className="quests-profile--wallet-trigger-icon"
                  style={{
                    zIndex: 3,
                  }}
                >
                  <img src={starknetIcon} alt="" />
                </div>
              )}
              {user?.ton_address && (
                <div
                  className="quests-profile--wallet-trigger-icon"
                  style={{
                    zIndex: 2,
                  }}
                >
                  <img src={tonIcon} alt="" />
                </div>
              )}
              {user?.solana_address && (
                <div
                  className="quests-profile--wallet-trigger-icon"
                  style={{
                    zIndex: 1,
                  }}
                >
                  <img src={solanaIcon} alt="" />
                </div>
              )}
              {user?.tron_address && (
                <div
                  className="wallet-popover--trigger-icon"
                  style={{
                    zIndex: 0,
                  }}
                >
                  <img src={tronWalletIcon} alt="" />
                </div>
              )}
            </div>
            {formatAddress(EVMAddress, 4, 3)}
            {!isOpen && <ArrowDownIcon />}
            {isOpen && <ArrowUpIcon />}
          </PopoverTrigger>
          {!isAllNonEVMConnected && (
            <button
              className="quests-profile--wallet-action mt-2"
              onClick={() => setIsConnectDialogOpen(true)}
            >
              <div className="image-stack">
                {!user?.starknet_address && (
                  <img src={starknetIcon} alt="" className="z-3" />
                )}
                {!user?.solana_address && (
                  <img src={solanaIcon} alt="" className="z-2" />
                )}
                {!user?.ton_address && (
                  <img src={tonIcon} alt="" className="z-1" />
                )}
                {!user?.tron_address && <img src={tronWalletIcon} alt="" />}
              </div>
              <span className="text-gradient">Link Non-EVM wallet</span>
            </button>
          )}
        </div>
        <AnimatePresence>
          {isOpen && (
            <PopoverPortal forceMount>
              <PopoverContent sideOffset={12} asChild>
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  className="quests-profile--wallet-menu"
                >
                  <div className="quests-profile--wallet-menu--item">
                    <Jazzicon
                      diameter={28}
                      seed={jsNumberForAddress(EVMAddress)}
                    />
                    <div>
                      <div className="quests-profile--wallet-menu--item-label">
                        Connected account
                      </div>
                      <div className="quests-profile--wallet-menu--item-value">
                        {formatAddress(EVMAddress, 7, 4)}
                      </div>
                    </div>
                  </div>
                  <PopoverClose
                    className="quests-profile--wallet-menu--action text-danger"
                    onClick={disconnect}
                  >
                    <PowerIcon />
                    Disconnect
                  </PopoverClose>
                  <hr className="quests-profile--wallet-menu--divider" />
                  {user?.starknet_address && (
                    <div className="quests-profile--wallet-menu--item">
                      <img
                        src={starknetWalletIcon}
                        className="quests-profile--wallet-menu--item-image"
                        alt=""
                      />
                      <div>
                        <div className="quests-profile--wallet-menu--item-label">
                          Starknet account
                        </div>
                        <div className="quests-profile--wallet-menu--item-value">
                          {formatAddress(user.starknet_address, 7, 4)}
                        </div>
                      </div>
                    </div>
                  )}
                  {user?.ton_address && (
                    <div className="quests-profile--wallet-menu--item">
                      <img
                        src={tonWalletIcon}
                        className="quests-profile--wallet-menu--item-image"
                        alt=""
                      />
                      <div>
                        <div className="quests-profile--wallet-menu--item-label">
                          TON account
                        </div>
                        <div className="quests-profile--wallet-menu--item-value">
                          {formatAddress(user.ton_address, 7, 4)}
                        </div>
                      </div>
                    </div>
                  )}
                  {user?.solana_address && (
                    <div className="quests-profile--wallet-menu--item">
                      <img
                        src={solanaWalletIcon}
                        className="quests-profile--wallet-menu--item-image"
                        alt=""
                      />
                      <div>
                        <div className="quests-profile--wallet-menu--item-label">
                          SOL account
                        </div>
                        <div className="quests-profile--wallet-menu--item-value">
                          {formatAddress(user.solana_address, 7, 4)}
                        </div>
                      </div>
                    </div>
                  )}
                  {user?.tron_address && (
                    <div className="quests-profile--wallet-menu--item">
                      <img
                        src={tronWalletIcon}
                        className="quests-profile--wallet-menu--item-image"
                        alt=""
                      />
                      <div>
                        <div className="quests-profile--wallet-menu--item-label">
                          Tron account
                        </div>
                        <div className="quests-profile--wallet-menu--item-value">
                          {formatAddress(user.tron_address, 7, 4)}
                        </div>
                      </div>
                    </div>
                  )}
                  {!isAllNonEVMConnected && (
                    <button
                      onClick={() => setIsConnectDialogOpen(true)}
                      className="quests-profile--wallet-menu--action text-gradient"
                    >
                      <PlusOutline width={14} height={14} color="#B29FFF" />
                      Link Non-EVM wallet
                    </button>
                  )}
                </motion.div>
              </PopoverContent>
            </PopoverPortal>
          )}
        </AnimatePresence>
      </Root>
      <ConnectWalletModalWithDisabled
        openModal={isConnectDialogOpen}
        setOpenModal={setIsConnectDialogOpen}
        onlyNonEVM
        disabledStarknet={!!user?.starknet_address}
        disabledTON={!!user?.ton_address}
        disabledSolana={!!user?.solana_address}
        disabledTron={!!user?.tron_address}
      />
    </div>
  );
}
